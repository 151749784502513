<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="ClientId" prop="clientId">
                <a-input v-model="queryParam.clientId" placeholder="请输入ClientId" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="ClientCode" prop="clientCode">
                <a-input v-model="queryParam.clientCode" placeholder="请输入ClientCode" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="创建人" prop="createdBy">
                  <a-input v-model="queryParam.createdBy" placeholder="请输入创建人" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="创建时间" prop="createdTime">
                  <a-date-picker
                    style="width: 100%"
                    v-model="queryParam.createdTime"
                    format="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="更新人" prop="updatedBy">
                  <a-input v-model="queryParam.updatedBy" placeholder="请输入更新人" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="更新时间" prop="updatedTime">
                  <a-date-picker
                    style="width: 100%"
                    v-model="queryParam.updatedTime"
                    format="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:openapi:client:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:openapi:client:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:openapi:client:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <!-- 无需导出功能
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:openapi:client:export']">
          <a-icon type="download" />导出
        </a-button> -->
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="ID"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="createdTime" slot-scope="text, record">
          {{ parseTime(record.createdTime) }}
        </span>
        <span slot="updatedTime" slot-scope="text, record">
          {{ parseTime(record.updatedTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:client:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:openapi:client:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:client:generateSecret']" />
          <a @click="handleReset(record)" v-hasPermi="['tenant:openapi:client:generateSecret']">
            <a-icon type="redo" />重置ClietSecret
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:client:generateSecret']" />
          <a @click="handleResetApiSecret(record)" v-hasPermi="['tenant:openapi:client:generateSecret']">
            <a-icon type="redo" />重置ApiSecret
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:client:resource']" />
          <a @click="handleResuorce(record)" v-hasPermi="['tenant:openapi:client:resource']">
            <a-icon type="form" />资源配置
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:client:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:openapi:client:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <vxe-modal
      v-model="ModalVisible"
      v-if="ModalVisible"
      width="1200px"
      height="700px"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="false"
      position="center"
      @close="handleCloseModal"
      class="vxe-container"
      transfer
    >
      <clientResource :clientInfo="clientInfo" showInModal></clientResource>
    </vxe-modal>
  </page-header-wrapper>
</template>

<script>
import { listClient, delClient, exportClient, resetClientSecret, resetApiSecret } from '@/api/openapi/client'
import CreateForm from './modules/CreateForm'
import clientResource from '../clientResource/index'

export default {
  name: 'Client',
  components: {
    CreateForm,
    clientResource
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        clientId: null,
        clientCode: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'ClientId',
          dataIndex: 'clientId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'ClientCode',
          dataIndex: 'clientCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Token限制次数(每日)',
          dataIndex: 'numberLimit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'ip白名單',
          dataIndex: 'whiteList',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属租户',
          dataIndex: 'tenantName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '更新人',
          dataIndex: 'updateBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          scopedSlots: { customRender: 'updateTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      ModalVisible: false,
      clientInfo: {}
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询调用开放接口的客户端信息列表 */
    getList() {
      this.loading = true
      listClient(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        clientId: undefined,
        clientCode: undefined,
        createdBy: undefined,
        createdTime: undefined,
        updatedBy: undefined,
        updatedTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.ID)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const that = this
      const IDs = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + IDs + '的数据',
        onOk() {
          return delClient(IDs).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 重置secret按钮操作 */
    handleReset(row) {
      var that = this
      const IDs = row.id || this.ids
      this.$confirm({
        title: '确认重置所选中租户的ClientSecret ？',
        width: 600,
        content: '当前选中的数据为：ClientId=' + row.clientId,
        onOk() {
          return resetClientSecret(IDs)
            .then((response) => {
              const h = that.$createElement
              that.$success({
                title: '重置成功，请及时保存以下新的ClientSecret：',
                width: 700,
                content: h('div', {}, [h('p', response.data.secret)]),
                onOk() {}
              })
            })
            .catch((err) => {
              that.$error({
                title: '重置失败',
                content: err
              })
            })
            .finally((res) => {
              that.getList()
            })
        },
        onCancel() {}
      })
    },
    /** 重置secret按钮操作 */
    handleResetApiSecret(row) {
      var that = this
      const IDs = row.id || this.ids
      this.$confirm({
        title: '确认重置所选中租户的ApiSecret ？',
        width: 600,
        content: '当前选中的数据为：ClientId=' + row.clientId,
        onOk() {
          return resetApiSecret(IDs)
            .then((response) => {
              const h = that.$createElement
              that.$success({
                title: '重置成功，请及时保存以下新的ApiSecret：',
                width: 700,
                content: h('div', {}, [h('p', response.data.secret)]),
                onOk() {}
              })
            })
            .catch((err) => {
              that.$error({
                title: '重置失败',
                content: err
              })
            })
            .finally((res) => {
              that.getList()
            })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportClient(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleResuorce(record) {
      // 跳转资源配置页面
      // this.$router.push({
      //   path:
      //     '/tenant/clientResource?clientId=' +
      //     record.clientId +
      //     '&tenantName=' +
      //     record.tenantName +
      //     '&tenantId=' +
      //     record.tenantId
      // })
      this.clientInfo = {
        clientId: record.clientId,
        tenantName: record.tenantName,
        tenantId: record.tenantId
      }
      this.ModalVisible = true
    },
    handleCloseModal() {
      this.ModalVisible = false
    }
  }
}
</script>
